<!-- eslint-disable -->
<template>
    <main>
      <section class="legal-section">
          <button @click="$router.go(-1)" class="modal-close"><span></span><span></span><span></span></button>
        <div class="grid-container">
          <div class="grid-row">
            <div class="col-12">
                <h1>Impressum</h1>
                <p>
                    <img loading="lazy" src="@/assets/images/imprint.png" alt="" width="261" height="300">
                    <br> Der Versicherungsschutz bezieht sich nicht auf Haftpflichtansprüche aus Tätigkeiten a) über in anderen Staaten eingerichtete oder unterhaltene Kanzleien oder Büros, b) im Zusammenhang mit der Beratung und Beschäftigung mit außereuropäischem Recht oder c) des Rechtsanwalts vor außereuropäischen Gerichten.
                </p>
                <p>Der räumliche Geltungsbereich bezieht sich auf die Mitgliedsländer der EU.</p>
                <p>Rechtsanwältinnen und Rechtsanwälte sind aufgrund der Bundesrechtsanwaltsordnung verpflichtet, eine Berufshaftpflichtversicherung mit einer Mindestversicherungssumme von 250.000 Euro zu unterhalten. Die Einzelheiten ergeben sich aus § 51 BRAO.</p>
                <p>Beide Rechtsanwälte sind in Deutschland zugelassen. Die Berufsbezeichnung Rechtsanwalt wurde beiden Rechtsanwälten in der Bundesrepublik Deutschland verliehen.</p>
                <p>Es gelten die berufsständischen Regelungen der Bundesrechtsanwaltsordnung (BRAO), der Berufsordnung für Rechtsanwälte (BORA), die Fachanwaltsord-nung (FAO), die Bundesgebührenordnung für Rechtsanwälte (BRAGO), das Rechtsanwaltsvergütungsgesetzes (RVG), die Fachanwaltsordnung, die Berufs-regeln der Rechtsanwälte in der Europäischen Union und das Gesetz über die Tätigkeit europäischer Rechtsanwälte in Deutschland (EURAG)</p>
                <p>Diese Vorschriften finden Sie im Wortlaut unter:<br> http://www.brak.de/seiten/06.php</p>
            </div>
          </div>
        </div>
      </section>
    </main>
</template>
<script>
export default {
  name: 'Imprint'
};
</script>
