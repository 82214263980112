/* eslint-disable no-else-return, prefer-template, no-param-reassign */
import axios from 'axios';

import { logger } from '@/common/logger';

const aws = require('aws-sdk');

aws.config.update({
  secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
  accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY,
});

const s3 = new aws.S3({
  signatureVersion: 'v4',
  region: process.env.VUE_APP_AWS_REGION,
});

export default s3;

export const singleUpload = (file) => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1;

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  const folder = yyyy + '' + mm + '' + dd;

  // const key = folder + '/' + Date.now() + '-' + encodeURI(file.name);
  const key = folder + '/' + Date.now() + '-' + Math.floor(Math.random() * 101);
  const params = {
    Bucket: process.env.VUE_APP_AWS_BUCKET,
    Key: key,
    Expires: 600, // 10 minutes
    ContentType: file.type,
  };
  const url = s3.getSignedUrl('putObject', params);
  return axios
    .put(url, file, {
      headers: {
        'Content-Type': file.type,
      },
    })
    .then((result) => {
      const bucketUrl = decodeURIComponent(result.request.responseURL).split(
        key,
      )[0];

      const res = result;
      res.key = key;
      res.fullPath = bucketUrl + key;

      return res;
    })
    .catch((err) => {
      // error handling
      logger.error('Upload Error (AWS) --- S3 Url: {url}; Error: {error}', url, err);
    });
};

export const deleteObjectByKey = (key) => {
  const params = {
    Bucket: process.env.VUE_APP_AWS_BUCKET,
    Key: key,
  };
  const data = s3.deleteObject(params).promise();
  console.log(data);
  return data;
};
