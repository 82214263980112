import Vue from 'vue';

import { logger } from '@/common/logger';

Vue.config.errorHandler = (err, vm, info) => {
  logger.error({ err, vm, info });
};

window.onerror = function (message, source, lineno, colno, error) {
  logger.error(
    {
      message, source, lineno, colno, error,
    },
  );
};
