<template>
    <main id="app" class="bc">
        <HeaderForm />
        <!-- Section content start-->
        <section class="content-section no-padding">
            <!-- Form section-->
            <div class="form-section no-padding">
                <transition name="fade" mode="out-in" appear>

                    <!-- Frage 1 Tatvorwurf-->
                    <Accusation key="1" v-if="step == 1" @next="next()" />

                    <!-- Frage 2 Versicherung-->
                    <Insurance key="2" v-if="step == 2" @next="next()" />

                    <!-- Frage 2 Upload-->
                    <Upload key="3" v-if="step == 3" @next="next()" />

                    <!-- Frage 12 Persönliche Daten-->
                    <section key="4" v-if="step == 4">
                    <ValidationObserver v-slot="{ invalid }">
                        <form @submit.prevent="sendData">
                            <div class="section index-section" key="personalData">
                                <div class="section-title">
                                    <h3 class="mb-2">4. Fast fertig! Wohin dürfen wir Dein Ergebnis senden?</h3>
                                </div>
                                <div class="text-center">
                                    <p class="small green-color">
                                    Die Prüfung Deines Falles ist für Dich <strong>kostenlos & unverbindlich.</strong> Es erfolgt keine Weitergabe Deiner Daten!
                                    </p>
                                </div>
                                <div class="form-container form-data">
                                    <div class="input-row two-input">
                                        <div class="wrap-input">
                                            <ValidationProvider mode="eager" name="vorname" rules="required" v-slot="{ errors }">
                                                <input type="text" v-model="user.firstname"><span class="label-input" v-bind:class="{ filled : user.firstname.length >= 1 }">Ihr Vorname *</span>
                                                <p class="form-error">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </div>
                                        <div class="wrap-input">
                                            <ValidationProvider mode="eager" name="nachname" rules="required" v-slot="{ errors }">
                                                <input type="text" v-model="user.name"><span class="label-input" v-bind:class="{ filled : user.name.length >= 1 }">Ihr Nachname *</span>
                                                <p class="form-error">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="input-row">
                                        <div class="wrap-input">
                                            <ValidationProvider mode="eager" name="email" rules="required|email" v-slot="{ errors }">
                                                <input type="email" v-model="user.email"><span class="label-input" v-bind:class="{ filled : user.email.length >= 1 }">Ihre E-Mail Adresse *</span>
                                                <p class="form-error">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="input-row">
                                        <div class="wrap-input">
                                            <ValidationProvider mode="eager" name="phone" rules="required" v-slot="{ errors }">
                                                <input type="phone" v-model="phone"><span class="label-input" v-bind:class="{ filled : phone.length >=1 }">Ihre Telefonnummer *</span>
                                                <p class="form-error">{{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-control" key="18">
                                <button type="submit" :disabled="invalid == true || formSubmitted == true" class="next"><span v-if="isLoading == true"></span>Auswertung kostenlos anfordern</button>
                            </div>
                            
                        </form>
                    </ValidationObserver>
                    </section>
                    <!-- Result-->
                    <div class="section index-section result-section" key="120" v-if="step == 5">
                        <div class="section-title">
                        <div class="icon-document"><img src="@/assets/images/icons-check-document-active.png"></div>
                        <h3>Anfrage erfolgreich eingegangen. <br> Deine Fallnummer lautet {{user.casenumber}}</h3>
                        <p>Vielen Dank für Deine Anfrage! Dein Fall ist nun in Bearbeitung und wir werden uns umgehend bei Dir melden.</p>
                        </div>
                    </div>
                </transition>
                <div class="form-control" v-if="step <= 3" key="18">
                    <transition name="fade">
                        <button class="next" v-if="step == 3 & printFiles.length > 0" v-on:click="next()"><span v-if="isLoading == true"></span>Weiter</button>
                    </transition>
                    <p v-show="step > 1"><a class="prev" href="#" v-on:click.prevent="prev()">
                        <svg>
                        <use xlink:href="@/assets/images/sprite.svg#right-arrow"></use>
                        </svg>Zurück</a>
                    </p>
                </div>
            </div>
        </section>
        <Footer />
    </main>
</template>
<script>
/*eslint-disable no-useless-escape*/
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

import axios from 'axios';
import { mapState, mapMutations } from 'vuex';
import { singleUpload } from '@/common/aws';
import { logger } from '@/common/logger';

// Implement own mail regex, due to not correctly identify wrong mails like 'mail@-example.de'
extend('email', value => {
    const EMAILREG = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    
    if (EMAILREG.test(value)) {
        return true;
    }
    return 'Bitte eine gültige E-Mail-Adresse eingeben';
});

// Override the default message.
extend('required', {
  ...required,
  message: 'Dies ist ein Pflichtfeld'
});

export default {
    name:'Form',
    components: {
        ValidationProvider,
        ValidationObserver,
        HeaderForm: () => import('./HeaderForm'),
        Footer: () => import('./Footer'),
        Accusation: () => import('./Accusation'),
        Insurance: () => import('./Insurance'),
        Upload: () => import('./Upload'),
    },
    data() {
        return {
            phone: '',
            formSubmitted: false,
            myLinks: [],
        };
    },
    computed: mapState({
        user: (state) => state.user,
        step: (state) => state.step,
        isLoading: (state) => state.isLoading,
        printFiles: (state) => state.printFiles
    }),
    methods:{
        ...mapMutations([
            'incrementChecked',
            'decrementChecked',
            'setChecked',
            'setStep',
            'incrementStep',
            'decrementStep',
            'setLoading',
            'updatePhoneNumber',
            'createCaseNumber',
            'resetUser'
        ]),
        prev() {
            this.decrementStep();
            
        },
        next() {
            this.setLoading(true);
            setTimeout(
            () =>  {
                this.incrementStep();
                this.setLoading(false);
            } , 500);
        },
        async sendData() {
            //TODO: gtm tracking
            if (this.$gtm.enabled()) {
                window.dataLayer?.push({
                    event: "formSubmission",
                    formType: "Lead",
                    formPosition: "Center"
                });
            }
            
            this.formSubmitted = true;
            this.$store.dispatch('setLoadingState', true);

            //remove non-numbers from string
            this.updatePhoneNumber(this.phone);
            
            //Create casenumber and save to user in vuex store.
            this.createCaseNumber();

            for (var i = 0; i < this.printFiles.length; i++) {
                let file = this.printFiles[i];

                const result = await singleUpload(
                    file
                )
                if (result.status === 200) {
                    // Handle storing it to your database here
                    // Adding links to Zoho CRM
                    this.myLinks.push(result.fullPath);
                    
                } else {
                    logger.error('Fehler in der FilePond Komponente - LeadId: {lead}; Fallnummer: {casenumber}', this.leadId, this.caseNumber);
                }
            }

            //send user data to lead service and get created lead id
            axios({
                method: 'post',
                data: {
                    user: this.user
                },
                url: `${process.env.VUE_APP_BBRAE_API_URL}/leads/insert_bc`,
                headers: {
                'Content-Type': 'application/json',
                },
            }).then((response) => {
                var leadId = response.data.leadId; // Extract the leadId string
                console.log('Received leadId:', leadId);

                if (leadId != '') {
                    for (let i = 0; i < this.myLinks.length; i++) {
                        const element = this.myLinks[i];
                        const pos = i + 1;
                        axios({
                            method: 'put',
                            data: {
                            link: element,
                            position: pos,
                            },
                            url: `${process.env.VUE_APP_BBRAE_API_URL}/leads/update/${leadId}`,
                            headers: {
                            'Content-Type': 'application/json',
                            },
                        }).then((response) => {
                            logger.info(response.data);
                        })
                        .catch(error => {
                            logger.error('Upload Error - LeadId: {lead}; Fallnummer: {casenumber};  Datei_{pos}: {link}', this.leadId, this.caseNumber, pos, element);
                            logger.error(error);
                        });
                    }
                    /*redirect to upload service
                    lid = leadid, fn = casenumber, 
                    fwd is used to guide the user through all followed processes
                    pr = product
                    */
                    this.setStep(5);
                    //window.location= `${process.env.VUE_APP_FRONTEND_UPLOAD_URL}?lid=${leadId}&fn=${this.user.casenumber}&fwd=true&pr=BUK`;
                } else {
                    logger.error('Fehler bei Übertragung an Zoho. Fallnummer:{Fallnummer};Name:{Name}, {Vorname};E-Mail: {Email}', this.user.casenumber, this.user.name, this.user.firstname, this.user.email);
                    this.setStep(5);
                    this.formSubmitted = false;
                }
                this.$store.dispatch('setLoadingState', false);
            })
            .catch((error) => {
                logger.error(error);
                logger.error('Fehler bei Übertragung an Zoho. Fallnummer:{Fallnummer};Name:{Name}, {Vorname};E-Mail: {Email}', this.user.casenumber, this.user.name, this.user.firstname, this.user.email);
                this.$store.dispatch('setLoadingState', false);
                this.formSubmitted = false;
            });

        }
    },
    created() {
        this.resetUser();
        this.setStep(1);
        if (this.$route.query.ref !== undefined || this.$route.query.ref !== '') {
            this.$store.dispatch('setRefCode', this.$route.query.ref);
            console.log(this.$route.query.ref);
        }
    },
}
</script>