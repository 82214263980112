var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"bc",attrs:{"id":"app"}},[_c('HeaderForm'),_c('section',{staticClass:"content-section no-padding"},[_c('div',{staticClass:"form-section no-padding"},[_c('transition',{attrs:{"name":"fade","mode":"out-in","appear":""}},[(_vm.step == 1)?_c('Accusation',{key:"1",on:{"next":function($event){return _vm.next()}}}):_vm._e(),(_vm.step == 2)?_c('Insurance',{key:"2",on:{"next":function($event){return _vm.next()}}}):_vm._e(),(_vm.step == 3)?_c('Upload',{key:"3",on:{"next":function($event){return _vm.next()}}}):_vm._e(),(_vm.step == 4)?_c('section',{key:"4"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.sendData($event)}}},[_c('div',{key:"personalData",staticClass:"section index-section"},[_c('div',{staticClass:"section-title"},[_c('h3',{staticClass:"mb-2"},[_vm._v("4. Fast fertig! Wohin dürfen wir Dein Ergebnis senden?")])]),_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"small green-color"},[_vm._v(" Die Prüfung Deines Falles ist für Dich "),_c('strong',[_vm._v("kostenlos & unverbindlich.")]),_vm._v(" Es erfolgt keine Weitergabe Deiner Daten! ")])]),_c('div',{staticClass:"form-container form-data"},[_c('div',{staticClass:"input-row two-input"},[_c('div',{staticClass:"wrap-input"},[_c('ValidationProvider',{attrs:{"mode":"eager","name":"vorname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.firstname),expression:"user.firstname"}],attrs:{"type":"text"},domProps:{"value":(_vm.user.firstname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "firstname", $event.target.value)}}}),_c('span',{staticClass:"label-input",class:{ filled : _vm.user.firstname.length >= 1 }},[_vm._v("Ihr Vorname *")]),_c('p',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"wrap-input"},[_c('ValidationProvider',{attrs:{"mode":"eager","name":"nachname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.name),expression:"user.name"}],attrs:{"type":"text"},domProps:{"value":(_vm.user.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "name", $event.target.value)}}}),_c('span',{staticClass:"label-input",class:{ filled : _vm.user.name.length >= 1 }},[_vm._v("Ihr Nachname *")]),_c('p',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"input-row"},[_c('div',{staticClass:"wrap-input"},[_c('ValidationProvider',{attrs:{"mode":"eager","name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],attrs:{"type":"email"},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "email", $event.target.value)}}}),_c('span',{staticClass:"label-input",class:{ filled : _vm.user.email.length >= 1 }},[_vm._v("Ihre E-Mail Adresse *")]),_c('p',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"input-row"},[_c('div',{staticClass:"wrap-input"},[_c('ValidationProvider',{attrs:{"mode":"eager","name":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],attrs:{"type":"phone"},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phone=$event.target.value}}}),_c('span',{staticClass:"label-input",class:{ filled : _vm.phone.length >=1 }},[_vm._v("Ihre Telefonnummer *")]),_c('p',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])]),_c('div',{key:"18",staticClass:"form-control"},[_c('button',{staticClass:"next",attrs:{"type":"submit","disabled":invalid == true || _vm.formSubmitted == true}},[(_vm.isLoading == true)?_c('span'):_vm._e(),_vm._v("Auswertung kostenlos anfordern")])])])]}}],null,false,3688153952)})],1):_vm._e(),(_vm.step == 5)?_c('div',{key:"120",staticClass:"section index-section result-section"},[_c('div',{staticClass:"section-title"},[_c('div',{staticClass:"icon-document"},[_c('img',{attrs:{"src":require("@/assets/images/icons-check-document-active.png")}})]),_c('h3',[_vm._v("Anfrage erfolgreich eingegangen. "),_c('br'),_vm._v(" Deine Fallnummer lautet "+_vm._s(_vm.user.casenumber))]),_c('p',[_vm._v("Vielen Dank für Deine Anfrage! Dein Fall ist nun in Bearbeitung und wir werden uns umgehend bei Dir melden.")])])]):_vm._e()],1),(_vm.step <= 3)?_c('div',{key:"18",staticClass:"form-control"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.step == 3 & _vm.printFiles.length > 0)?_c('button',{staticClass:"next",on:{"click":function($event){return _vm.next()}}},[(_vm.isLoading == true)?_c('span'):_vm._e(),_vm._v("Weiter")]):_vm._e()]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.step > 1),expression:"step > 1"}]},[_c('a',{staticClass:"prev",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.prev()}}},[_c('svg',[_c('use',{attrs:{"xlink:href":require("@/assets/images/sprite.svg") + "#right-arrow"}})]),_vm._v("Zurück")])])],1):_vm._e()],1)]),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }