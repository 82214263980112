<template>
    <main id="app" class="bc">
        <Header />
        <!-- Section content start-->
        <section class="content-section no-padding">
            <!-- Form section-->
            <div class="index-section">
                <div class="section">
                    <div class="section-title">
                        <div class="author"><img src="@/assets/images/icon.png"></div>
                        <h3 class="mb-2">Kostenloser Bussgeldcheck</h3>
                        <p>Wir prüfen Deinen Bußgeldbescheid kostenlos auf Fehler und gleichen ihn mit unserer umfangreichen Blitzerdatenbank ab. Finden wir Unstimmigkeiten, erhältst Du von uns Empfehlungen zur Abwehr.</p>
                    </div>
                    <div class="form-container">
                        <router-link to="/form" class="next">Kostenlose Prüfung starten</router-link>
                        <div class="header-partners">
                            <p>Bekannt aus Rundfunk und TV</p>
                            <div class="partners-img"><img src="@/assets/images/welt_testimonial.png"><img src="@/assets/images/heise_testimonial.png"><img src="@/assets/images/br_testimonial.png"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </main>
</template>
<script>
export default {
    components: {
        Header: () => import('./Header'),
        
        Footer: () => import('./Footer'),
    },
    created() {
        if (this.$route.query.ref !== undefined || this.$route.query.ref !== '') {
            this.$store.dispatch('setRefCode', this.$route.query.ref);
        }
    },
}
</script>