<!-- eslint-disable -->
<template>
    <main>
      <section class="legal-section">
        <button @click="$router.go(-1)" class="modal-close"><span></span><span></span><span></span></button>
        <div class="grid-container">
          <div class="grid-row">
            <div class="col-12">
            <h1>Datenschutzerklärung</h1>
            <p>Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck der Verarbeitung von personenbezogenen
                Daten (nachfolgend kurz „Daten“) innerhalb unseres Onlineangebotes und der mit ihm verbundenen Webseiten,
                Funktionen und Inhalte sowie externen Onlinepräsenzen, wie z.B. unser Social Media Profile auf (nachfolgend
                gemeinsam bezeichnet als „Onlineangebot“). Im Hinblick auf die verwendeten Begrifflichkeiten, wie z.B.
                „Verarbeitung“ oder „Verantwortlicher“ verweisen wir auf die Definitionen im Art. 4 der
                Datenschutzgrundverordnung (DSGVO).</p>
            <h3>Verantwortlicher</h3>
            <p><img loading="lazy"
                    src="@/assets/images/imprint.png"
                    alt="" width="261" height="300"></p>
            <p><router-link to="/impressum">Impressum</router-link></p>
            <p>&nbsp;</p>
            <h3 id="dsg-general-datatype">Arten der verarbeiteten Daten:</h3>
            <p>– Bestandsdaten (z.B., Namen, Adressen).<br> – Kontaktdaten (z.B., E-Mail, Telefonnummern).<br> – Inhaltsdaten
                (z.B., Texteingaben, Fotografien, Videos).<br> – Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten,
                Zugriffszeiten).<br> – Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen).</p>
            <h3 id="dsg-general-datasubjects">Kategorien betroffener Personen</h3>
            <p>Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir die betroffenen Personen zusammenfassend auch
                als „Nutzer“).</p>
            <h3 id="dsg-general-purpose">Zweck der Verarbeitung</h3>
            <p>– Zurverfügungstellung des Onlineangebotes, seiner Funktionen und Inhalte.<br> – Beantwortung von Kontaktanfragen
                und Kommunikation mit Nutzern.<br> – Sicherheitsmaßnahmen.<br> – Reichweitenmessung/Marketing</p>
            <h3 id="dsg-general-terms">Verwendete Begrifflichkeiten</h3>
            <p>„Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte oder identifizierbare
                natürliche Person (im Folgenden „betroffene Person“) beziehen; als identifizierbar wird eine natürliche Person
                angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer
                Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren besonderen
                Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen,
                wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind.</p>
            <p>„Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche
                Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch jeden
                Umgang mit Daten.</p>
            <p>„Pseudonymisierung“ die Verarbeitung personenbezogener Daten in einer Weise, dass die personenbezogenen Daten
                ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet werden
                können, sofern diese zusätzlichen Informationen gesondert aufbewahrt werden und technischen und
                organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht einer
                identifizierten oder identifizierbaren natürlichen Person zugewiesen werden.</p>
            <p>„Profiling“ jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese
                personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person
                beziehen, zu bewerten, insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche Lage, Gesundheit,
                persönliche Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser
                natürlichen Person zu analysieren oder vorherzusagen.</p>
            <p>Als „Verantwortlicher“ wird die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die
                allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
                entscheidet, bezeichnet.</p>
            <p>„Auftragsverarbeiter“ eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die
                personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.</p>
            <h3 id="dsg-general-legalbasis">Maßgebliche Rechtsgrundlagen</h3>
            <p>Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen unserer Datenverarbeitungen mit. Sofern die
                Rechtsgrundlage in der Datenschutzerklärung nicht genannt wird, gilt Folgendes: Die Rechtsgrundlage für die
                Einholung von Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO, die Rechtsgrundlage für die Verarbeitung
                zur Erfüllung unserer Leistungen und Durchführung vertraglicher Maßnahmen sowie Beantwortung von Anfragen ist
                Art. 6 Abs. 1 lit. b DSGVO, die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer rechtlichen
                Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO, und die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer
                berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO. Für den Fall, dass lebenswichtige Interessen der
                betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich
                machen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.</p>
            <h3 id="dsg-general-securitymeasures">Sicherheitsmaßnahmen</h3>
            <p>Wir treffen nach Maßgabe des Art. 32 DSGVO unter Berücksichtigung des Stands der Technik, der
                Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der
                unterschiedlichen Eintrittswahrscheinlichkeit und Schwere des Risikos für die Rechte und Freiheiten natürlicher
                Personen, geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu
                gewährleisten.</p>
            <p>Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von Daten
                durch Kontrolle des physischen Zugangs zu den Daten, als auch des sie betreffenden Zugriffs, der Eingabe,
                Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet,
                die eine Wahrnehmung von Betroffenenrechten, Löschung von Daten und Reaktion auf Gefährdung der Daten
                gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei der Entwicklung, bzw.
                Auswahl von Hardware, Software sowie Verfahren, entsprechend dem Prinzip des Datenschutzes durch
                Technikgestaltung und durch datenschutzfreundliche Voreinstellungen (Art. 25 DSGVO).</p>
            <h3 id="dsg-general-coprocessing">Zusammenarbeit mit Auftragsverarbeitern und Dritten</h3>
            <p>Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen Personen und Unternehmen (Auftragsverarbeitern
                oder Dritten) offenbaren, sie an diese übermitteln oder ihnen sonst Zugriff auf die Daten gewähren, erfolgt dies
                nur auf Grundlage einer gesetzlichen Erlaubnis (z.B. wenn eine Übermittlung der Daten an Dritte, wie an
                Zahlungsdienstleister, gem. Art. 6 Abs. 1 lit. b DSGVO zur Vertragserfüllung erforderlich ist), Sie eingewilligt
                haben, eine rechtliche Verpflichtung dies vorsieht oder auf Grundlage unserer berechtigten Interessen (z.B. beim
                Einsatz von Beauftragten, Webhostern, etc.).</p>
            <p>Sofern wir Dritte mit der Verarbeitung von Daten auf Grundlage eines sog. „Auftragsverarbeitungsvertrages“
                beauftragen, geschieht dies auf Grundlage des Art. 28 DSGVO.</p>
            <h3 id="dsg-general-thirdparty">Übermittlungen in Drittländer</h3>
            <p>Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen Union (EU) oder des Europäischen
                Wirtschaftsraums (EWR)) verarbeiten oder dies im Rahmen der Inanspruchnahme von Diensten Dritter oder
                Offenlegung, bzw. Übermittlung von Daten an Dritte geschieht, erfolgt dies nur, wenn es zur Erfüllung unserer
                (vor)vertraglichen Pflichten, auf Grundlage Ihrer Einwilligung, aufgrund einer rechtlichen Verpflichtung oder
                auf Grundlage unserer berechtigten Interessen geschieht. Vorbehaltlich gesetzlicher oder vertraglicher
                Erlaubnisse, verarbeiten oder lassen wir die Daten in einem Drittland nur beim Vorliegen der besonderen
                Voraussetzungen der Art. 44 ff. DSGVO verarbeiten. D.h. die Verarbeitung erfolgt z.B. auf Grundlage besonderer
                Garantien, wie der offiziell anerkannten Feststellung eines der EU entsprechenden Datenschutzniveaus (z.B. für
                die USA durch das „Privacy Shield“) oder Beachtung offiziell anerkannter spezieller vertraglicher
                Verpflichtungen (so genannte „Standardvertragsklauseln“).</p>
            <h3 id="dsg-general-rightssubject">Rechte der betroffenen Personen</h3>
            <p>Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf
                Auskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend Art. 15 DSGVO.</p>
            <p>Sie haben entsprechend. Art. 16 DSGVO das Recht, die Vervollständigung der Sie betreffenden Daten oder die
                Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.</p>
            <p>Sie haben nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen, dass betreffende Daten unverzüglich gelöscht
                werden, bzw. alternativ nach Maßgabe des Art. 18 DSGVO eine Einschränkung der Verarbeitung der Daten zu
                verlangen.</p>
            <p>Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die Sie uns bereitgestellt haben nach Maßgabe
                des Art. 20 DSGVO zu erhalten und deren Übermittlung an andere Verantwortliche zu fordern.</p>
            <p>Sie haben ferner gem. Art. 77 DSGVO das Recht, eine Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen.
            </p>
            <h3 id="dsg-general-revokeconsent">Widerrufsrecht</h3>
            <p>Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3 DSGVO mit Wirkung für die Zukunft zu widerrufen
            </p>
            <h3 id="dsg-general-object">Widerspruchsrecht</h3>
            <p>Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach Maßgabe des Art. 21 DSGVO jederzeit
                widersprechen. Der Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der Direktwerbung erfolgen.
            </p>
            <h3 id="dsg-general-cookies">Cookies und Widerspruchsrecht bei Direktwerbung</h3>
            <p>Als „Cookies“ werden kleine Dateien bezeichnet, die auf Rechnern der Nutzer gespeichert werden. Innerhalb der
                Cookies können unterschiedliche Angaben gespeichert werden. Ein Cookie dient primär dazu, die Angaben zu einem
                Nutzer (bzw. dem Gerät auf dem das Cookie gespeichert ist) während oder auch nach seinem Besuch innerhalb eines
                Onlineangebotes zu speichern. Als temporäre Cookies, bzw. „Session-Cookies“ oder „transiente Cookies“, werden
                Cookies bezeichnet, die gelöscht werden, nachdem ein Nutzer ein Onlineangebot verlässt und seinen Browser
                schließt. In einem solchen Cookie kann z.B. der Inhalt eines Warenkorbs in einem Onlineshop oder ein
                Login-Status gespeichert werden. Als „permanent“ oder „persistent“ werden Cookies bezeichnet, die auch nach dem
                Schließen des Browsers gespeichert bleiben. So kann z.B. der Login-Status gespeichert werden, wenn die Nutzer
                diese nach mehreren Tagen aufsuchen. Ebenso können in einem solchen Cookie die Interessen der Nutzer gespeichert
                werden, die für Reichweitenmessung oder Marketingzwecke verwendet werden. Als „Third-Party-Cookie“ werden
                Cookies bezeichnet, die von anderen Anbietern als dem Verantwortlichen, der das Onlineangebot betreibt,
                angeboten werden (andernfalls, wenn es nur dessen Cookies sind spricht man von „First-Party Cookies“).</p>
            <p>Wir können temporäre und permanente Cookies einsetzen und klären hierüber im Rahmen unserer Datenschutzerklärung
                auf.</p>
            <p>Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner gespeichert werden, werden sie gebeten die
                entsprechende Option in den Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte Cookies können in
                den Systemeinstellungen des Browsers gelöscht werden. Der Ausschluss von Cookies kann zu
                Funktionseinschränkungen dieses Onlineangebotes führen.</p>
            <p>Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des Onlinemarketing eingesetzten Cookies kann bei
                einer Vielzahl der Dienste, vor allem im Fall des Trackings, über die US-amerikanische Seite <a
                    href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a> oder die EU-Seite <a
                    href="http://www.youronlinechoices.com/">http://www.youronlinechoices.com/</a> erklärt werden. Des Weiteren
                kann die Speicherung von Cookies mittels deren Abschaltung in den Einstellungen des Browsers erreicht werden.
                Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen dieses Onlineangebotes genutzt werden können.
            </p>
            <h3 id="dsg-general-erasure">Löschung von Daten</h3>
            <p>Die von uns verarbeiteten Daten werden nach Maßgabe der Art. 17 und 18 DSGVO gelöscht oder in ihrer Verarbeitung
                eingeschränkt. Sofern nicht im Rahmen dieser Datenschutzerklärung ausdrücklich angegeben, werden die bei uns
                gespeicherten Daten gelöscht, sobald sie für ihre Zweckbestimmung nicht mehr erforderlich sind und der Löschung
                keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Sofern die Daten nicht gelöscht werden, weil sie für
                andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung eingeschränkt. D.h. die Daten
                werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels- oder
                steuerrechtlichen Gründen aufbewahrt werden müssen.</p>
            <p>Nach gesetzlichen Vorgaben in Deutschland, erfolgt die Aufbewahrung insbesondere für 10 Jahre gemäß §§ 147 Abs. 1
                AO, 257 Abs. 1 Nr. 1 und 4, Abs. 4 HGB (Bücher, Aufzeichnungen, Lageberichte, Buchungsbelege, Handelsbücher, für
                Besteuerung relevanter Unterlagen, etc.) und 6 Jahre gemäß § 257 Abs. 1 Nr. 2 und 3, Abs. 4 HGB (Handelsbriefe).
            </p>
            <p>Nach gesetzlichen Vorgaben in Österreich erfolgt die Aufbewahrung insbesondere für 7 J gemäß § 132 Abs. 1 BAO
                (Buchhaltungsunterlagen, Belege/Rechnungen, Konten, Belege, Geschäftspapiere, Aufstellung der Einnahmen und
                Ausgaben, etc.), für 22 Jahre im Zusammenhang mit Grundstücken und für 10 Jahre bei Unterlagen im Zusammenhang
                mit elektronisch erbrachten Leistungen, Telekommunikations-, Rundfunk- und Fernsehleistungen, die an
                Nichtunternehmer in EU-Mitgliedstaaten erbracht werden und für die der Mini-One-Stop-Shop (MOSS) in Anspruch
                genommen wird.</p>
            <h3 id="dsg-commercialpurpose">Geschäftsbezogene Verarbeitung</h3>
            <p><span class="ts-muster-content">Zusätzlich verarbeiten wir<br> – Vertragsdaten (z.B., Vertragsgegenstand,
                    Laufzeit, Kundenkategorie).<br> – Zahlungsdaten (z.B., Bankverbindung, Zahlungshistorie)<br> von unseren
                    Kunden, Interessenten und Geschäftspartner zwecks Erbringung vertraglicher Leistungen, Service und
                    Kundenpflege, Marketing, Werbung und Marktforschung.</span></p>
            <h3 id="dsg-affiliate-general">Teilnahme an Affiliate-Partnerprogrammen</h3>
            <p><span class="ts-muster-content">Innerhalb unseres Onlineangebotes setzen wir auf Grundlage unserer berechtigten
                    Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes)
                    gem. Art. 6 Abs. 1 lit. f DSGVO branchenübliche Trackingmaßnahmen ein, soweit diese für den Betrieb des
                    Affiliatesystems erforderlich sind. Nachfolgend klären wir die Nutzer über die technischen Hintergründe
                    auf.</span></p>
            <p>Die von unseren Vertragspartnern angebotene Leistungen können auch auf anderen Webseiten beworben und verlinkt
                werden (sog. Affiliate-Links oder After-Buy-Systeme, wenn z.B. Links oder Leistungen Dritter nach einem
                Vertragsschluss angeboten werden). Die Betreiber der jeweiligen Webseiten erhalten eine Provision, wenn Nutzer
                den Affiliate-Links folgen und anschließend die Angebote wahrnehmen.</p>
            <p>Zusammenfassend, ist es für unser Onlineangebot erforderlich, dass wir nachverfolgen können, ob Nutzer, die sich
                für Affiliate-Links und/oder die bei uns verfügbaren Angebote interessieren, die Angebote anschließend auf die
                Veranlassung der Affiliate-Links oder unserer Onlineplattform, wahrnehmen. Hierzu werden die Affiliate-Links und
                unsere Angebote um bestimmte Werte ergänzt, die ein Bestandteil des Links oder anderweitig, z.B. in einem
                Cookie, gesetzt werden können. Zu den Werten gehören insbesondere die Ausgangswebseite (Referrer), Zeitpunkt,
                eine Online-Kennung der Betreiber der Webseite, auf der sich der Affiliate-Link befand, eine Online-Kennung des
                jeweiligen Angebotes, eine Online-Kennung des Nutzers, als auch Tracking-spezifische Werte wie z.B.
                Werbemittel-ID, Partner-ID und Kategorisierungen.</p>
            <p>Bei der von uns verwendeten Online-Kennungen der Nutzer, handelt es sich um pseudonyme Werte. D.h. die
                Online-Kennungen enthalten selbst keine personenbezogenen Daten wie Namen oder E-Mailadressen. Sie helfen uns
                nur zu bestimmen ob derselbe Nutzer, der auf einen Affiliate-Link geklickt oder sich über unser Onlineangebot
                für ein Angebot interessiert hat, das Angebot wahrgenommen, d.h. z.B. einen Vertrag mit dem Anbieter
                abgeschlossen hat. Die Online-Kennung ist jedoch insoweit personenbezogen, als dem Partnerunternehmen und auch
                uns, die Online-Kennung zusammen mit anderen Nutzerdaten vorliegen. Nur so kann das Partnerunternehmen uns
                mitteilen, ob derjenige Nutzer das Angebot wahrgenommen hat und wir z.B. den Bonus auszahlen können.</p>
            <h3 id="dsg-contact">Kontaktaufnahme</h3>
            <p><span class="ts-muster-content">Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon oder
                    via sozialer Medien) werden die Angaben des Nutzers zur Bearbeitung der Kontaktanfrage und deren Abwicklung
                    gem. Art. 6 Abs. 1 lit. b) DSGVO verarbeitet. Die Angaben der Nutzer können in einem
                    Customer-Relationship-Management System (“CRM System”) oder vergleichbarer Anfragenorganisation gespeichert
                    werden.</span></p>
            <p>Wir löschen die Anfragen, sofern diese nicht mehr erforderlich sind. Wir überprüfen die Erforderlichkeit alle
                zwei Jahre; Ferner gelten die gesetzlichen Archivierungspflichten.</p>
            <h3 id="dsg-newsletter-de">Newsletter</h3>
            <p><span class="ts-muster-content">Mit den nachfolgenden Hinweisen informieren wir Sie über die Inhalte unseres
                    Newsletters sowie das Anmelde-, Versand- und das statistische Auswertungsverfahren sowie Ihre
                    Widerspruchsrechte auf. Indem Sie unseren Newsletter abonnieren, erklären Sie sich mit dem Empfang und den
                    beschriebenen Verfahren einverstanden.</span></p>
            <p>Inhalt des Newsletters: Wir versenden Newsletter, E-Mails und weitere elektronische Benachrichtigungen mit
                werblichen Informationen (nachfolgend „Newsletter“) nur mit der Einwilligung der Empfänger oder einer
                gesetzlichen Erlaubnis. Sofern im Rahmen einer Anmeldung zum Newsletter dessen Inhalte konkret umschrieben
                werden, sind sie für die Einwilligung der Nutzer maßgeblich. Im Übrigen enthalten unsere Newsletter
                Informationen zu unseren Leistungen und uns.</p>
            <p>Double-Opt-In und Protokollierung: Die Anmeldung zu unserem Newsletter erfolgt in einem sog.
                Double-Opt-In-Verfahren. D.h. Sie erhalten nach der Anmeldung eine E-Mail, in der Sie um die Bestätigung Ihrer
                Anmeldung gebeten werden. Diese Bestätigung ist notwendig, damit sich niemand mit fremden E-Mailadressen
                anmelden kann. Die Anmeldungen zum Newsletter werden protokolliert, um den Anmeldeprozess entsprechend den
                rechtlichen Anforderungen nachweisen zu können. Hierzu gehört die Speicherung des Anmelde- und des
                Bestätigungszeitpunkts, als auch der IP-Adresse. Ebenso werden die Änderungen Ihrer bei dem Versanddienstleister
                gespeicherten Daten protokolliert.</p>
            <p>Anmeldedaten: Um sich für den Newsletter anzumelden, reicht es aus, wenn Sie Ihre E-Mailadresse angeben. Optional
                bitten wir Sie einen Namen, zwecks persönlicher Ansprache im Newsletters anzugeben.</p>
            <p>Der Versand des Newsletters und die mit ihm verbundene Erfolgsmessung erfolgen auf Grundlage einer Einwilligung
                der Empfänger gem. Art. 6 Abs. 1 lit. a, Art. 7 DSGVO i.V.m § 7 Abs. 2 Nr. 3 UWG oder falls eine Einwilligung
                nicht erforderlich ist, auf Grundlage unserer berechtigten Interessen am Direktmarketing gem. Art. 6 Abs. 1 lt.
                f. DSGVO i.V.m. § 7 Abs. 3 UWG.</p>
            <p>Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage unserer berechtigten Interessen gem. Art. 6 Abs.
                1 lit. f DSGVO. Unser Interesse richtet sich auf den Einsatz eines nutzerfreundlichen sowie sicheren
                Newslettersystems, das sowohl unseren geschäftlichen Interessen dient, als auch den Erwartungen der Nutzer
                entspricht und uns ferner den Nachweis von Einwilligungen erlaubt.</p>
            <p>Kündigung/Widerruf – Sie können den Empfang unseres Newsletters jederzeit kündigen, d.h. Ihre Einwilligungen
                widerrufen. Einen Link zur Kündigung des Newsletters finden Sie am Ende eines jeden Newsletters. Wir können die
                ausgetragenen E-Mailadressen bis zu drei Jahren auf Grundlage unserer berechtigten Interessen speichern bevor
                wir sie löschen, um eine ehemals gegebene Einwilligung nachweisen zu können. Die Verarbeitung dieser Daten wird
                auf den Zweck einer möglichen Abwehr von Ansprüchen beschränkt. Ein individueller Löschungsantrag ist jederzeit
                möglich, sofern zugleich das ehemalige Bestehen einer Einwilligung bestätigt wird.</p>
            <h3 id="dsg-newsletter-provider-cleverreach">Newsletter – CleverReach</h3>
            <p><span class="ts-muster-content">Der Versand der Newsletter erfolgt mittels des Versanddienstleisters CleverReach
                    GmbH &amp; Co. KG, Mühlenstr. 43, 26180 Rastede, Deutschland. Die Datenschutzbestimmungen des
                    Versanddienstleisters können Sie hier einsehen: <a href="https://www.cleverreach.com/de/datenschutz/"
                        target="_blank" rel="noopener">https://www.cleverreach.com/de/datenschutz/</a>. Der Versanddienstleister
                    wird auf Grundlage unserer berechtigten Interessen gem. Art. 6 Abs. 1 lit. f DSGVO und eines
                    Auftragsverarbeitungsvertrages gem. Art. 28 Abs. 3 S. 1 DSGVO eingesetzt.</span></p>
            <p>Der Versanddienstleister kann die Daten der Empfänger in pseudonymer Form, d.h. ohne Zuordnung zu einem Nutzer,
                zur Optimierung oder Verbesserung der eigenen Services nutzen, z.B. zur technischen Optimierung des Versandes
                und der Darstellung der Newsletter oder für statistische Zwecke verwenden. Der Versanddienstleister nutzt die
                Daten unserer Newsletterempfänger jedoch nicht, um diese selbst anzuschreiben oder um die Daten an Dritte
                weiterzugeben.</p>
            <h3 id="dsg-newsletter-analytics">Newsletter – Erfolgsmessung</h3>
            <p><span class="ts-muster-content">Die Newsletter enthalten einen sog. „web-beacon“, d.h. eine pixelgroße Datei, die
                    beim Öffnen des Newsletters von unserem Server, bzw. sofern wir einen Versanddienstleister einsetzen, von
                    dessen Server abgerufen wird. Im Rahmen dieses Abrufs werden zunächst technische Informationen, wie
                    Informationen zum Browser und Ihrem System, als auch Ihre IP-Adresse und Zeitpunkt des Abrufs
                    erhoben.</span></p>
            <p>Diese Informationen werden zur technischen Verbesserung der Services anhand der technischen Daten oder der
                Zielgruppen und ihres Leseverhaltens anhand derer Abruforte (die mit Hilfe der IP-Adresse bestimmbar sind) oder
                der Zugriffszeiten genutzt. Zu den statistischen Erhebungen gehört ebenfalls die Feststellung, ob die Newsletter
                geöffnet werden, wann sie geöffnet werden und welche Links geklickt werden. Diese Informationen können aus
                technischen Gründen zwar den einzelnen Newsletterempfängern zugeordnet werden. Es ist jedoch weder unser
                Bestreben, noch, sofern eingesetzt, das des Versanddienstleisters, einzelne Nutzer zu beobachten. Die
                Auswertungen dienen uns viel mehr dazu, die Lesegewohnheiten unserer Nutzer zu erkennen und unsere Inhalte auf
                sie anzupassen oder unterschiedliche Inhalte entsprechend den Interessen unserer Nutzer zu versenden.</p>
            <p>Ein getrennter Widerruf der Erfolgsmessung ist leider nicht möglich, in diesem Fall muss das gesamte
                Newsletterabonnement gekündigt werden.</p>
            <h3 id="dsg-tracking-tagmanager">Google Tag Manager</h3>
            <p><span class="ts-muster-content">Google Tag Manager ist eine Lösung, mit der wir sog. Website-Tags über eine
                    Oberfläche verwalten können (und so z.B. Google Analytics sowie andere Google-Marketing-Dienste in unser
                    Onlineangebot einbinden). Der Tag Manager selbst (welches die Tags implementiert) verarbeitet keine
                    personenbezogenen Daten der Nutzer. Im Hinblick auf die Verarbeitung der personenbezogenen Daten der Nutzer
                    wird auf die folgenden Angaben zu den Google-Diensten verwiesen. Nutzungsrichtlinien: <a
                        href="https://www.google.com/intl/de/tagmanager/use-policy.html" target="_blank"
                        rel="noopener">https://www.google.com/intl/de/tagmanager/use-policy.html</a>.<br> </span></p>
            <h3 id="dsg-ga-googleanalytics">Google Analytics</h3>
            <p><span class="ts-muster-content">Wir setzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der
                    Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f.
                    DSGVO) Google Analytics, einen Webanalysedienst der Google LLC („Google“) ein. Google verwendet Cookies. Die
                    durch das Cookie erzeugten Informationen über Benutzung des Onlineangebotes durch die Nutzer werden in der
                    Regel an einen Server von Google in den USA übertragen und dort gespeichert.</span></p>
            <p>Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische
                Datenschutzrecht einzuhalten (<a
                    href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active" target="_blank"
                    rel="noopener">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</a>).</p>
            <p>Google wird diese Informationen in unserem Auftrag benutzen, um die Nutzung unseres Onlineangebotes durch die
                Nutzer auszuwerten, um Reports über die Aktivitäten innerhalb dieses Onlineangebotes zusammenzustellen und um
                weitere, mit der Nutzung dieses Onlineangebotes und der Internetnutzung verbundene Dienstleistungen, uns
                gegenüber zu erbringen. Dabei können aus den verarbeiteten Daten pseudonyme Nutzungsprofile der Nutzer erstellt
                werden.</p>
            <p>Wir setzen Google Analytics nur mit aktivierter IP-Anonymisierung ein. Das bedeutet, die IP-Adresse der Nutzer
                wird von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des
                Abkommens über den Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an
                einen Server von Google in den USA übertragen und dort gekürzt.</p>
            <p>Die von dem Browser des Nutzers übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
                Die Nutzer können die Speicherung der Cookies durch eine entsprechende Einstellung ihrer Browser-Software
                verhindern; die Nutzer können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf ihre Nutzung
                des Onlineangebotes bezogenen Daten an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem
                sie das unter folgendem Link verfügbare Browser-Plugin herunterladen und installieren: <a
                    href="http://tools.google.com/dlpage/gaoptout?hl=de" target="_blank"
                    rel="noopener">http://tools.google.com/dlpage/gaoptout?hl=de</a>.</p>
            <p>Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten, erfahren Sie in
                der Datenschutzerklärung von Google (<a href="https://policies.google.com/technologies/ads" target="_blank"
                    rel="noopener">https://policies.google.com/technologies/ads</a>) sowie in den Einstellungen für die
                Darstellung von Werbeeinblendungen durch Google <a href="https://adssettings.google.com/authenticated"
                    target="_blank" rel="noopener">(https://adssettings.google.com/authenticated</a>).</p>
            <p>Die personenbezogenen Daten der Nutzer werden nach 14 Monaten gelöscht oder anonymisiert.</p>
            <h3 id="dsg-ga-audiences">Zielgruppenbildung mit Google Analytics</h3>
            <p><span class="ts-muster-content">Wir setzen Google Analytics ein, um die durch innerhalb von Werbediensten Googles
                    und seiner Partner geschalteten Anzeigen, nur solchen Nutzern anzuzeigen, die auch ein Interesse an unserem
                    Onlineangebot gezeigt haben oder die bestimmte Merkmale (z.B. Interessen an bestimmten Themen oder
                    Produkten, die anhand der besuchten Webseiten bestimmt werden) aufweisen, die wir an Google übermitteln
                    (sog. „Remarketing-“, bzw. „Google-Analytics-Audiences“). Mit Hilfe der Remarketing Audiences möchten wir
                    auch sicherstellen, dass unsere Anzeigen dem potentiellen Interesse der Nutzer entsprechen.<br> </span></p>
            <h3 id="dsg-tracking-adsense-personalized">Google Adsense mit personalisierten Anzeigen</h3>
            <p><span class="ts-muster-content">Wir nutzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der
                    Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f.
                    DSGVO) die Dienste der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA,
                    („Google“).</span></p>
            <p>Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische
                Datenschutzrecht einzuhalten (<a
                    href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active" target="_blank"
                    rel="noopener">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</a>).</p>
            <p>Wir Nutzen den Dienst AdSense, mit dessen Hilfe Anzeigen in unsere Webseite eingeblendet und wir für deren
                Einblendung oder sonstige Nutzung eine Entlohnung erhalten. Zu diesen Zwecken werden Nutzungsdaten, wie z.B. der
                Klick auf eine Anzeige und die IP-Adresse der Nutzer verarbeitet, wobei die IP-Adresse um die letzten beiden
                Stellen gekürzt wird. Daher erfolgt die Verarbeitung der Daten der Nutzer pseudonymisiert.</p>
            <p>Wir setzen Adsense mit personalisierten Anzeigen ein. Dabei zieht Google auf Grundlage der von Nutzern besuchten
                Websites oder verwendeten Apps und den so erstellten Nutzerprofilen Rückschlüsse auf deren Interessen.
                Werbetreibende nutzen diese Informationen, um ihre Kampagnen an diesen Interessen auszurichten, was für Nutzer
                und Werbetreibende gleichermaßen von Vorteil ist. Für Google sind Anzeigen dann personalisiert, wenn erfasste
                oder bekannte Daten die Anzeigenauswahl bestimmen oder beeinflussen. Dazu zählen unter anderem frühere
                Suchanfragen, Aktivitäten, Websitebesuche, die Verwendung von Apps, demografische und Standortinformationen. Im
                Einzelnen umfasst dies: demografisches Targeting, Targeting auf Interessenkategorien, Remarketing sowie
                Targeting auf Listen zum Kundenabgleich und Zielgruppenlisten, die in DoubleClick Bid Manager oder Campaign
                Manager hochgeladen wurden.</p>
            <p>Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten, erfahren Sie in
                der Datenschutzerklärung von Google (<a href="https://policies.google.com/technologies/ads" target="_blank"
                    rel="noopener">https://policies.google.com/technologies/ads</a>) sowie in den Einstellungen für die
                Darstellung von Werbeeinblendungen durch Google <a href="https://adssettings.google.com/authenticated"
                    target="_blank" rel="noopener">(https://adssettings.google.com/authenticated</a>).</p>
            <h3 id="dsg-tracking-adwords">Google AdWords und Conversion-Messung</h3>
            <p><span class="ts-muster-content">Wir nutzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der
                    Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f.
                    DSGVO) die Dienste der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA,
                    („Google“).</span></p>
            <p>Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische
                Datenschutzrecht einzuhalten
                (https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active).</p>
            <p>Wir nutzen das Onlinemarketingverfahren Google “AdWords”, um Anzeigen im Google-Werbe-Netzwerk zu platzieren
                (z.B., in Suchergebnissen, in Videos, auf Webseiten, etc.), damit sie Nutzern angezeigt werden, die ein
                mutmaßliches Interesse an den Anzeigen haben. Dies erlaubt uns Anzeigen für und innerhalb unseres
                Onlineangebotes gezielter anzuzeigen, um Nutzern nur Anzeigen zu präsentieren, die potentiell deren Interessen
                entsprechen. Falls einem Nutzer z.B. Anzeigen für Produkte angezeigt werden, für die er sich auf anderen
                Onlineangeboten interessiert hat, spricht man hierbei vom „Remarketing“. Zu diesen Zwecken wird bei Aufruf
                unserer und anderer Webseiten, auf denen das Google-Werbe-Netzwerk aktiv ist, unmittelbar durch Google ein Code
                von Google ausgeführt und es werden sog. (Re)marketing-Tags (unsichtbare Grafiken oder Code, auch als “Web
                Beacons” bezeichnet) in die Webseite eingebunden. Mit deren Hilfe wird auf dem Gerät der Nutzer ein
                individuelles Cookie, d.h. eine kleine Datei abgespeichert (statt Cookies können auch vergleichbare Technologien
                verwendet werden). In dieser Datei wird vermerkt, welche Webseiten der Nutzer aufgesucht, für welche Inhalte er
                sich interessiert und welche Angebote der Nutzer geklickt hat, ferner technische Informationen zum Browser und
                Betriebssystem, verweisende Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung des Onlineangebotes.</p>
            <p>Ferner erhalten wir ein individuelles „Conversion-Cookie“. Die mit Hilfe des Cookies eingeholten Informationen
                dienen Google dazu, Conversion-Statistiken für uns zu erstellen. Wir erfahren jedoch nur die anonyme
                Gesamtanzahl der Nutzer, die auf unsere Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag
                versehenen Seite weitergeleitet wurden. Wir erhalten jedoch keine Informationen, mit denen sich Nutzer
                persönlich identifizieren lassen.</p>
            <p>Die Daten der Nutzer werden im Rahmen des Google-Werbe-Netzwerks pseudonym verarbeitet. D.h. Google speichert und
                verarbeitet z.B. nicht den Namen oder E-Mailadresse der Nutzer, sondern verarbeitet die relevanten Daten
                cookie-bezogen innerhalb pseudonymer Nutzerprofile. D.h. aus der Sicht von Google werden die Anzeigen nicht für
                eine konkret identifizierte Person verwaltet und angezeigt, sondern für den Cookie-Inhaber, unabhängig davon wer
                dieser Cookie-Inhaber ist. Dies gilt nicht, wenn ein Nutzer Google ausdrücklich erlaubt hat, die Daten ohne
                diese Pseudonymisierung zu verarbeiten. Die über die Nutzer gesammelten Informationen werden an Google
                übermittelt und auf Googles Servern in den USA gespeichert.</p>
            <p>Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten, erfahren Sie in
                der Datenschutzerklärung von Google (<a href="https://policies.google.com/technologies/ads" target="_blank"
                    rel="noopener">https://policies.google.com/technologies/ads</a>) sowie in den Einstellungen für die
                Darstellung von Werbeeinblendungen durch Google <a href="https://adssettings.google.com/authenticated"
                    target="_blank" rel="noopener">(https://adssettings.google.com/authenticated</a>).</p>
            <h3 id="dsg-tracking-doubleclick">Google Doubleclick</h3>
            <p><span class="ts-muster-content">Wir nutzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der
                    Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f.
                    DSGVO) die Dienste der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA,
                    („Google“).</span></p>
            <p>Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische
                Datenschutzrecht einzuhalten
                (https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active).</p>
            <p>Wir nutzen das Onlinemarketingverfahren Google “Doubleclick”, um Anzeigen im Google-Werbe-Netzwerk zu platzieren
                (z.B., in Suchergebnissen, in Videos, auf Webseiten, etc.). Double Click zeichnet sich dadurch aus, dass
                Anzeigen in Echtzeit anhand mutmaßlicher Interessen der Nutzer angezeigt werden. Dies erlaubt uns Anzeigen für
                und innerhalb unseres Onlineangebotes gezielter anzuzeigen, um Nutzern nur Anzeigen zu präsentieren, die
                potentiell deren Interessen entsprechen. Falls einem Nutzer z.B. Anzeigen für Produkte angezeigt werden, für die
                er sich auf anderen Onlineangeboten interessiert hat, spricht man hierbei vom „Remarketing“. Zu diesen Zwecken
                wird bei Aufruf unserer und anderer Webseiten, auf denen das Google-Werbe-Netzwerk aktiv ist, unmittelbar durch
                Google ein Code von Google ausgeführt und es werden sog. (Re)marketing-Tags (unsichtbare Grafiken oder Code,
                auch als “Web Beacons” bezeichnet) in die Webseite eingebunden. Mit deren Hilfe wird auf dem Gerät der Nutzer
                ein individuelles Cookie, d.h. eine kleine Datei abgespeichert (statt Cookies können auch vergleichbare
                Technologien verwendet werden). In dieser Datei wird vermerkt, welche Webseiten der Nutzer aufgesucht, für
                welche Inhalte er sich interessiert und welche Angebote der Nutzer geklickt hat, ferner technische Informationen
                zum Browser und Betriebssystem, verweisende Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung des
                Onlineangebotes.</p>
            <p>Es wird ebenfalls die IP-Adresse der Nutzer erfasst, wobei diese innerhalb von Mitgliedstaaten der Europäischen
                Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt und nur in
                Ausnahmefällen ganz an einen Server von Google in den USA übertragen und dort gekürzt wird. Die vorstehend
                genannten Informationen können seitens Google auch mit solchen Informationen aus anderen Quellen verbunden
                werden. Wenn der Nutzer anschließend andere Webseiten besucht, können ihm entsprechend seiner mutmaßlichen
                Interessen auf Grundlage seines Nutzerprofiles auf ihn abgestimmten Anzeigen angezeigt werden.</p>
            <p>Die Daten der Nutzer werden im Rahmen des Google-Werbe-Netzwerks pseudonym verarbeitet. D.h. Google speichert und
                verarbeitet z.B. nicht den Namen oder E-Mailadresse der Nutzer, sondern verarbeitet die relevanten Daten
                cookie-bezogen innerhalb pseudonymer Nutzerprofile. D.h. aus der Sicht von Google werden die Anzeigen nicht für
                eine konkret identifizierte Person verwaltet und angezeigt, sondern für den Cookie-Inhaber, unabhängig davon wer
                dieser Cookie-Inhaber ist. Dies gilt nicht, wenn ein Nutzer Google ausdrücklich erlaubt hat, die Daten ohne
                diese Pseudonymisierung zu verarbeiten. Die von Google-Marketing-Services über die Nutzer gesammelten
                Informationen werden an Google übermittelt und auf Googles Servern in den USA gespeichert.</p>
            <p>Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten, erfahren Sie in
                der Datenschutzerklärung von Google (<a href="https://policies.google.com/technologies/ads" target="_blank"
                    rel="noopener">https://policies.google.com/technologies/ads</a>) sowie in den Einstellungen für die
                Darstellung von Werbeeinblendungen durch Google <a href="https://adssettings.google.com/authenticated"
                    target="_blank" rel="noopener">(https://adssettings.google.com/authenticated</a>).</p>
            <h3 id="dsg-tracking-bing">Bing Ads</h3>
            <p><span class="ts-muster-content">Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer berechtigten
                    Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes
                    im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) das Conversion- und Tracking-Tool „Bing Ads“ der Microsoft
                    Corporation, One Microsoft Way, Redmond, WA 98052-6399, USA, ein. Dabei werden von Microsoft Cookies auf den
                    Geräten der Nutzer gespeichert, um eine Analyse der Benutzung unseres Onlineangebotes durch die Nutzer zu
                    ermöglichen, sofern Nutzer über eine Microsoft-Bing-Anzeige auf unser Onlineangebot gelangt sind (sog.
                    “Conversion-Messung”). Microsoft und wir können auf diese Weise erkennen, dass jemand auf eine Anzeige
                    geklickt hat, zu unserem Onlineangebot weitergeleitet wurde und eine vorher bestimmte Zielseite (sog
                    “Conversion Seite”) erreicht hat. Wir erfahren dabei nur die Gesamtzahl der Nutzer, die auf eine Bing
                    Anzeige geklickt haben und dann zu der Conversion Seite weitergeleitet wurden. Es werden keine IP-Adressen
                    gespeichert. Es werden keine persönlichen Informationen zur Identität der Nutzer mitgeteilt.</span></p>
            <p>Microsoft ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische
                Datenschutzrecht einzuhalten (<a
                    href="https://www.privacyshield.gov/participant?id=a2zt0000000KzNaAAK&amp;status=Active" target="_blank"
                    rel="noopener">https://www.privacyshield.gov/participant?id=a2zt0000000KzNaAAK&amp;status=Active</a>).</p>
            <p>Wenn Nutzer nicht an dem Tracking-Verfahren von Bing Ads teilnehmen möchten, können Sie auch das hierfür
                erforderliche Setzen eines Cookies per Browser-Einstellung deaktivieren oder die Opt-Out-Seite von Microsoft
                nutzen: <a href="http://choice.microsoft.com/de-DE/opt-out" target="_blank"
                    rel="noopener">http://choice.microsoft.com/de-DE/opt-out</a>.</p>
            <p>Weitere Informationen zum Datenschutz und zu den eingesetzten Cookies bei Microsoft Bing Ads finden Nutzer in der
                Datenschutzerklärung von Microsoft: <a href="https://privacy.microsoft.com/de-de/privacystatement"
                    target="_blank" rel="noopener">https://privacy.microsoft.com/de-de/privacystatement</a>.</p>
            <h3 id="dsg-optimizing-vwo">Visual Website Optimizer</h3>
            <p><span class="ts-muster-content">Innerhalb unseres Onlineangebotes wird auf Grundlage unserer berechtigten
                    Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes
                    im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) der Dienst Visual Website Optimizer (ein Angebot der Wingify
                    Software Private Limited, 404, Gopal Heights, Netaji Subhash Place, Pitam Pura, Delhi 110034, India)
                    eingesetzt.</span></p>
            <p>Visual Website Optimizer erlaubt im Rahmen so genannten “A/B-Testings”, “Clicktracking” und “Heatmaps”
                nachzuvollziehen, wie sich verschiedene Änderungen einer Website auswirken (z.B. Veränderungen der
                Eingabefelder, des Designs, etc.). A/B Tests dienen der Verbesserung der Benutzerfreundlichkeit und Performance
                von Onlineangeboten. Dabei werden Nutzer z.B. unterschiedliche Versionen einer Website oder ihrer Elemente, wie
                Eingabeformulare, dargestellt, auf denen sich die Platzierung der Inhalte oder Beschriftungen der
                Navigationselemente unterscheiden können. Anschließend kann anhand des Verhaltens der Nutzer, z.B. längeres
                Verweilen auf der Webseite oder häufigerer Interaktion mit den Elementen, feststellt werden welche dieser
                Webseiten oder Elemente eher den Bedürfnissen der Nutzer entsprechen. „Clicktracking“ erlaubt es die Bewegungen
                der Nutzer innerhalb eines gesamten Onlineangebotes zu überblicken. Da die Ergebnisse dieser Tests genauer sind,
                wenn die Interaktion der Nutzer über einen gewissen Zeitraum verfolgt werden kann (z.B. schauen können, ob ein
                Nutzer gerne wiederkehrt), werden für diese Test-Zwecke im Regelfall Cookies auf den Rechnern der Nutzer
                gespeichert. “Heatmaps” sind Mausbewegungen der Nutzer, die zu einem Gesamtbild zusammengefasst werden, mit
                dessen Hilfe z.B. erkannt werden kann, welche Webseitenelemente bevorzugt angesteuert werden und welche
                Webseitenelemente Nutzer weniger bevorzugen.</p>
            <p>Nur für diese Testzwecke werden Cookies auf den Geräten der Nutzer abgelegt. Dabei werden nur pseudonyme Daten
                der Nutzer verarbeitet. Für weitere Informationen verweisen wir auf die Datenschutzerklärung von Visual Website
                Optimizer: <a href="https://vwo.com/privacy-policy/" target="_blank"
                    rel="noopener">https://vwo.com/privacy-policy/</a>.</p>
            <h3></h3>
            <h3 id="dsg-socialmedia">Onlinepräsenzen in sozialen Medien</h3>
            <p><span class="ts-muster-content">Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und Plattformen, um
                    mit den dort aktiven Kunden, Interessenten und Nutzern kommunizieren und sie dort über unsere Leistungen
                    informieren zu können. Beim Aufruf der jeweiligen Netzwerke und Plattformen gelten die Geschäftsbedingungen
                    und die Datenverarbeitungsrichtlinien deren jeweiligen Betreiber.</span></p>
            <p>Soweit nicht anders im Rahmen unserer Datenschutzerklärung angegeben, verarbeiten wir die Daten der Nutzer sofern
                diese mit uns innerhalb der sozialen Netzwerke und Plattformen kommunizieren, z.B. Beiträge auf unseren
                Onlinepräsenzen verfassen oder uns Nachrichten zusenden.</p>
            <h3 id="dsg-thirdparty-einleitung">Einbindung von Diensten und Inhalten Dritter</h3>
            <p><span class="ts-muster-content">Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer berechtigten
                    Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes
                    im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Inhalts- oder Serviceangebote von Drittanbietern ein, um deren
                    Inhalte und Services, wie z.B. Videos oder Schriftarten einzubinden (nachfolgend einheitlich bezeichnet als
                    “Inhalte”).</span></p>
            <p>Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte, die IP-Adresse der Nutzer wahrnehmen, da sie ohne
                die IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die IP-Adresse ist damit für die Darstellung
                dieser Inhalte erforderlich. Wir bemühen uns nur solche Inhalte zu verwenden, deren jeweilige Anbieter die
                IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Drittanbieter können ferner so genannte Pixel-Tags
                (unsichtbare Grafiken, auch als “Web Beacons” bezeichnet) für statistische oder Marketingzwecke verwenden. Durch
                die “Pixel-Tags” können Informationen, wie der Besucherverkehr auf den Seiten dieser Website ausgewertet werden.
                Die pseudonymen Informationen können ferner in Cookies auf dem Gerät der Nutzer gespeichert werden und unter
                anderem technische Informationen zum Browser und Betriebssystem, verweisende Webseiten, Besuchszeit sowie
                weitere Angaben zur Nutzung unseres Onlineangebotes enthalten, als auch mit solchen Informationen aus anderen
                Quellen verbunden werden.</p>
            <h3 id="dsg-thirdparty-youtube">Youtube</h3>
            <p><span class="ts-muster-content">Wir binden die Videos der Plattform “YouTube” des Anbieters Google LLC, 1600
                    Amphitheatre Parkway, Mountain View, CA 94043, USA, ein. Datenschutzerklärung: <a
                        href="https://www.google.com/policies/privacy/" target="_blank"
                        rel="noopener">https://www.google.com/policies/privacy/</a>, Opt-Out: <a
                        href="https://adssettings.google.com/authenticated" target="_blank"
                        rel="noopener">https://adssettings.google.com/authenticated</a>.</span></p>
            <h3 id="dsg-thirdparty-googlefonts">Google Fonts</h3>
            <p><span class="ts-muster-content">Wir binden die Schriftarten (“Google Fonts”) des Anbieters Google LLC, 1600
                    Amphitheatre Parkway, Mountain View, CA 94043, USA, ein. Datenschutzerklärung: <a
                        href="https://www.google.com/policies/privacy/" target="_blank"
                        rel="noopener">https://www.google.com/policies/privacy/</a>, Opt-Out: <a
                        href="https://adssettings.google.com/authenticated" target="_blank"
                        rel="noopener">https://adssettings.google.com/authenticated</a>.</span></p>
            <h3 id="dsg-thirdparty-googlerecaptcha">Google ReCaptcha</h3>
            <p><span class="ts-muster-content">Wir binden die Funktion zur Erkennung von Bots, z.B. bei Eingaben in
                    Onlineformularen (“ReCaptcha”) des Anbieters Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043,
                    USA, ein. Datenschutzerklärung: <a href="https://www.google.com/policies/privacy/" target="_blank"
                        rel="noopener">https://www.google.com/policies/privacy/</a>, Opt-Out: <a
                        href="https://adssettings.google.com/authenticated" target="_blank"
                        rel="noopener">https://adssettings.google.com/authenticated</a>.</span></p>
            <h3 id="dsg-thirdparty-googlemaps">Google Maps</h3>
            <p><span class="ts-muster-content">Wir binden die Landkarten des Dienstes “Google Maps” des Anbieters Google LLC,
                    1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, ein. Zu den verarbeiteten Daten können insbesondere
                    IP-Adressen und Standortdaten der Nutzer gehören, die jedoch nicht ohne deren Einwilligung (im Regelfall im
                    Rahmen der Einstellungen ihrer Mobilgeräte vollzogen), erhoben werden. Die Daten können in den USA
                    verarbeitet werden. Datenschutzerklärung: <a href="https://www.google.com/policies/privacy/" target="_blank"
                        rel="noopener">https://www.google.com/policies/privacy/</a>, Opt-Out: <a
                        href="https://adssettings.google.com/authenticated" target="_blank"
                        rel="noopener">https://adssettings.google.com/authenticated</a>.</span></p>
            <p><a class="dsg1-5" href="https://datenschutz-generator.de" target="_blank" rel="nofollow noopener">Erstellt mit
                    Datenschutz-Generator.de von RA Dr. Thomas Schwenke</a><br></p>
        </div>
        </div>
        </div>
      </section>
    </main>
</template>
<script>
export default {
  name: 'Privacy'
};
</script>
