<!-- eslint-disable max-len -->
<template>
  <div class="form-section">
    <div class="section result-section" key="16">
      <div class="section-title">
        <div class="icon-document"><img src="@/assets/images/icons-check-document-active.png"></div>
        <h3>Seite nicht vorhanden</h3>
        <p>Die eingegebene Url ist nicht vorhanden, bitte stellen Sie sicher, dass Sie die korrekte Adresse im Browser eingegeben haben.</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ErrorPage',
};
</script>
