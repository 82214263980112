/* eslint-disable */

import {LoggerConfiguration, ConsoleSink, SeqSink} from 'serilogger';

/**
 * @description Logger class
 * This is responsible for logging of all kind of stuff in the application
 * Default, we are using the console api for logging and this provides the basic level of logging such as
 * you can use the available method of console in developement but in production these will be replaced with empty methods
 * This can be extended with the help of adding Log level functionality
 */
class AppLogger {
  /**
   * @constructor AppLogger
   */
  
  constructor() {
    /** Initializing the configuration of logger */
    this.initLogger();
  }

  /**
   * @description Initializing the configuration such as if environment is production then all log method will be replaced with empty methods
   * except logToServer, which will be responsible for logging the important stuff on server
   */
  initLogger() {

    const slog = new LoggerConfiguration()
                    .writeTo(new ConsoleSink())
                    .writeTo(new SeqSink({
                      url: "http://seq-log-alb-1250787945.eu-central-1.elb.amazonaws.com",
                      apiKey: "opbLnOCSobHWCCcf0z7V",
                      compact: true,
                    }))
                    .create();
    
    this.debug = slog.debug.bind(slog);

    this.info = slog.info.bind(slog);

    this.warn = slog.warn.bind(slog);

    this.error = slog.error.bind(slog);
  }
}

/** Creating the instance of logger */
const logger = new AppLogger();

export { logger };
